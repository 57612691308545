body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
}

.projects-section {
  background-color: #f0f0f0; 
  padding: 40px 0; 
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.project-card {
  background-color: #ffffff; 
  border: 1px solid #ced4da; 
  transition: box-shadow 0.3s ease-in-out; 
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-card:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.image-wrapper {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.project-card:hover .overlay {
  opacity: 1;
}

.project-card .card-body {
  flex: 0 0 auto;
}

.github-tile {
  margin-bottom: 20px;
}

footer {
  margin-top: auto;
}

@media (max-width: 1200px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: 1fr;
  }
  .project-card {
    margin-bottom: 20px;
  }
}
